import React, { SyntheticEvent } from 'react';
import { Control } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { useAppTitle } from '@hooks/use-app-title';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { FormModel } from './form-config';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { IPasswordResetCredentials } from './password-reset-credentials.interface';

type Props = {
  control: Control<FormModel>;
  credentials: IPasswordResetCredentials;
  isVerifying: boolean;
  requestVerifyEmail: (event: SyntheticEvent) => Promise<void>;
};

export const VerifyEmail: React.FC<Props> = ({ control, credentials, isVerifying, requestVerifyEmail }) => {
  useAppTitle('Verify Email');

  const isButtonDisabled = credentials.email.trim() === '';

  return (
    <form onSubmit={requestVerifyEmail}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          We will send a confirmation code to your email to reset your current password
        </Typography>
        <ControlledTextField control={control} name="email" label="Email" type="email" required />
        <AppLoadingButton
          variant="contained"
          size="large"
          type="submit"
          disabled={isButtonDisabled}
          isLoading={isVerifying}
          fullWidth
        >
          Submit
        </AppLoadingButton>
      </Stack>
    </form>
  );
};
