import React, { SyntheticEvent } from 'react';
import { Control } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { useAppTitle } from '@hooks/use-app-title';
import { FormModel } from './form-config';
import { IPasswordResetCredentials } from './password-reset-credentials.interface';

type Props = {
  control: Control<FormModel>;
  credentials: IPasswordResetCredentials;
  isConfirming: boolean;
  isAttemptsExceeded: boolean;
  isVerifying: boolean;
  requestConfirmPassword: (event: SyntheticEvent) => Promise<void>;
  requestResendCode: (event: SyntheticEvent) => Promise<void>;
};

export const ConfirmCode: React.FC<Props> = ({
  control,
  credentials,
  isConfirming,
  isVerifying,
  isAttemptsExceeded,
  requestConfirmPassword,
  requestResendCode,
}) => {
  useAppTitle('Confirm Code');

  const isButtonDisabled = credentials.resetPasswordToken.trim() === '' || isAttemptsExceeded;

  return (
    <form onSubmit={requestConfirmPassword}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Confirm code from Email
        </Typography>
        <ControlledTextField
          control={control}
          name="resetPasswordToken"
          label="Code"
          type="number"
          inputProps={{ minLength: 4 }}
          required
        />

        {isAttemptsExceeded && (
          <AppLoadingButton
            variant="contained"
            size="large"
            type="button"
            onClick={requestResendCode}
            isLoading={isVerifying}
            fullWidth
          >
            Resend Confirmation Code
          </AppLoadingButton>
        )}

        <AppLoadingButton
          variant="contained"
          size="large"
          type="submit"
          isLoading={isConfirming}
          disabled={isButtonDisabled}
          fullWidth
        >
          Submit
        </AppLoadingButton>
      </Stack>
    </form>
  );
};
