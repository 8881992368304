import { SyntheticEvent, useState } from 'react';
import { Control, useForm } from 'react-hook-form';
import { useConfirmPasswordMutation, useVerifyMutation } from '@api/api-slice';
import { isWithStatus } from '@api/is-with-status.guard';
import { AuthStateType, AuthStates } from './auth-state.type';
import { FormModel } from './form-config';
import { IPasswordResetCredentials } from './password-reset-credentials.interface';

const values: IPasswordResetCredentials = {
  email: '',
  password: '',
  passwordConfirmation: '',
  resetPasswordToken: '',
};

type HookResult = {
  isVerifying: boolean;
  isConfirming: boolean;
  isAttemptsExceeded: boolean;
  activeStep: AuthStateType;
  control: Control<FormModel>;
  credentials: IPasswordResetCredentials;
  requestVerifyEmail: (event: SyntheticEvent) => Promise<void>;
  handleSetPassword: () => void;
  requestConfirmPassword: (event: SyntheticEvent) => Promise<void>;
  requestResendCode: (event: SyntheticEvent) => Promise<void>;
};

export function useResetPassword(): HookResult {
  const [activeStep, setActiveStep] = useState<AuthStateType>(AuthStates.VerifyEmail);
  const [isAttemptsExceeded, setIsAttemptsExceeded] = useState(false);
  const { control, watch } = useForm({ values });
  const credentials = watch();

  const [verifyMutation, verifyFlags] = useVerifyMutation();
  const [confirmMutation, confirmFlags] = useConfirmPasswordMutation();

  async function requestVerifyEmail(event: SyntheticEvent): Promise<void> {
    event.preventDefault();
    try {
      await verifyMutation(credentials.email).unwrap();
      setActiveStep(AuthStates.ConfirmPassword);
    } catch (errorResponse) {
      console.error(errorResponse);
    }
  }

  async function requestConfirmPassword(event: SyntheticEvent): Promise<void> {
    event.preventDefault();
    try {
      await confirmMutation(credentials).unwrap();
      setActiveStep(AuthStates.Complete);
    } catch (errorResponse: unknown) {
      if (isWithStatus(errorResponse)) {
        if (errorResponse.status === 429) {
          setIsAttemptsExceeded(true);
        }
      }
    }
  }

  async function requestResendCode(event: SyntheticEvent): Promise<void> {
    event.preventDefault();
    try {
      await verifyMutation(credentials.email).unwrap();
      setIsAttemptsExceeded(false);
    } catch (errorResponse: unknown) {
      console.error(errorResponse);
    }
  }

  function handleSetPassword(): void {
    setActiveStep(AuthStates.ConfirmCode);
  }

  return {
    isVerifying: verifyFlags.isLoading,
    isConfirming: confirmFlags.isLoading,
    isAttemptsExceeded,
    activeStep,
    control,
    credentials,
    requestVerifyEmail,
    handleSetPassword,
    requestConfirmPassword,
    requestResendCode,
  };
}
