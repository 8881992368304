import React, { PropsWithChildren, SyntheticEvent, useState } from 'react';
import { Button, ButtonProps, Link, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

type Props = PropsWithChildren<{
  textToCopy: string;
  tooltipText?: string;
  label?: string;
}> &
  ButtonProps;

const ShowCopiedTimeoutMs = 5000;

export const CopyToClipboard: React.FC<Props> = ({
  textToCopy,
  tooltipText,
  label = 'Copy to clipboard',
  children,
  ...buttonProps
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, ShowCopiedTimeoutMs);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const buttonLabel = isCopied ? 'Copied!' : label;
  const buttonIcon = isCopied ? <CheckIcon /> : <ContentCopyIcon />;

  return (
    <Tooltip title={tooltipText ?? buttonLabel}>
      {children ? (
        <Link onClick={copyToClipboard} sx={{ cursor: 'pointer', textDecorationStyle: 'dotted' }}>
          {children}
        </Link>
      ) : (
        <Button
          {...buttonProps}
          size={buttonProps.size ?? 'small'}
          onClick={copyToClipboard}
          startIcon={label ? buttonIcon : undefined}
        >
          {label || buttonIcon}
        </Button>
      )}
    </Tooltip>
  );
};
