import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Card, Link, Stack, Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { useAppTitle } from '@hooks/use-app-title';
import { Routes } from '@routes/routes';
import { VerifyEmail } from './VerifyEmail';
import { ConfirmPassword } from './ConfirmPassword';
import { ConfirmCode } from './ConfirmCode';
import { AuthStates } from './auth-state.type';
import { useResetPassword } from './use-password-reset';

export const Reset: React.FC = () => {
  const {
    isVerifying,
    isConfirming,
    isAttemptsExceeded,
    activeStep,
    control,
    credentials,
    requestVerifyEmail,
    handleSetPassword,
    requestConfirmPassword,
    requestResendCode,
  } = useResetPassword();

  useAppTitle('Reset Password');

  const renderCurrentView = (): JSX.Element => {
    switch (activeStep) {
      case AuthStates.VerifyEmail:
        return (
          <VerifyEmail
            control={control}
            credentials={credentials}
            isVerifying={isVerifying}
            requestVerifyEmail={requestVerifyEmail}
          />
        );
      case AuthStates.ConfirmPassword:
        return <ConfirmPassword control={control} credentials={credentials} handleSetPassword={handleSetPassword} />;
      case AuthStates.ConfirmCode:
        return (
          <ConfirmCode
            control={control}
            credentials={credentials}
            isConfirming={isConfirming}
            isVerifying={isVerifying}
            isAttemptsExceeded={isAttemptsExceeded}
            requestConfirmPassword={requestConfirmPassword}
            requestResendCode={requestResendCode}
          />
        );
      case AuthStates.Complete:
        return (
          <Stack direction="column" alignItems="center" spacing={2} sx={{ marginTop: 3 }}>
            <Typography>Password updated!</Typography>
            <Link component={NavLink} to={Routes.login}>
              Go to Login
            </Link>
          </Stack>
        );
    }
  };

  return (
    <Box sx={{ background: '#B2BFCB', height: '100vh', padding: '128px 0' }}>
      <Card sx={{ padding: 6, paddingBottom: 3, width: 290, margin: 'auto' }}>
        <Typography variant="h4">Password Reset</Typography>
        {renderCurrentView()}
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', my: 2 }}>
          <MobileStepper
            variant="dots"
            steps={4}
            position="static"
            activeStep={activeStep}
            sx={{ maxWidth: 400 }}
            nextButton={null}
            backButton={null}
          />
        </Box>
        {activeStep === AuthStates.VerifyEmail && (
          <Link component={NavLink} to={Routes.login}>
            Back to Login
          </Link>
        )}
      </Card>
    </Box>
  );
};
