import { IBackendLabelOption } from './backend-label.type';
import { isNil } from 'lodash';
import { EmptyCell } from '@utils/empty-cell';

export function getDisplayText(value: unknown, labelOptions?: IBackendLabelOption<string | number | null>[]): string {
  if (Array.isArray(value)) {
    return value.map((item) => labelOptions?.find((option) => option.value === item)?.label ?? EmptyCell).join(', ');
  }

  if (labelOptions) {
    // Find label for the value, if not found use EmptyCell
    return labelOptions.find((option) => option.value === value)?.label ?? EmptyCell;
  }
  // Return string value, use EmptyCell for undefined and null values
  return isNil(value) ? EmptyCell : String(value);
}
