export const AuthStates = {
  VerifyEmail: 0,
  ConfirmPassword: 1,
  ConfirmCode: 2,
  Complete: 3,
} as const;

type Keys = keyof typeof AuthStates;

export type AuthStateType = (typeof AuthStates)[Keys];
