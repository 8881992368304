import React, { useMemo } from 'react';
import { CopyToClipboard } from '@components/CopyToClipboard';
import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { getDisplayText } from '@features/backend-label/get-display-text';

type Column = {
  field: string;
  headerName?: string;
  optionKey?: keyof AppBackendLabels;
};

type Props<T> = {
  rows: T[];
  columns: Column[];
};

// Function to construct a tab-separated table string
function createTabSeparatedTable<T>(rows: T[], columns: Column[], backendLabels: AppBackendLabels): string {
  // Construct table header
  const header = columns.map((column) => column.headerName || column.field).join('\t');

  // Construct table body
  const body = rows
    .map((row) =>
      columns
        .map(({ field, optionKey }) =>
          getDisplayText(row[field as keyof T], optionKey ? backendLabels[optionKey] : undefined),
        )
        .join('\t'),
    )
    .join('\n');

  return `${header}\n${body}`;
}

export const CopyTableToClipboard: <T>(props: Props<T>) => JSX.Element = ({ rows, columns }) => {
  const backendLabels = useAppBackendLabels([]);
  const tableString = useMemo(
    () => createTabSeparatedTable(rows, columns, backendLabels),
    [rows, columns, backendLabels],
  );
  return <CopyToClipboard textToCopy={tableString} />;
};
