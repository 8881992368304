import React from 'react';
import { ControlledFileField } from '@components/inputs/ControlledFileField';
import { Grid, Stack, Typography } from '@mui/material';
import { ControlledAutocomplete } from '@components/inputs/ControlledAutocomplete';
import { ISensitivePersonalInformation } from './sensitive-personal-information.interface';
import { useAppBackendLabels } from '@features/backend-label/use-app-backend-labels';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { AppLoadingButton } from '@components/AppLoadingButton';
import { useDisableRole } from '@features/auth/use-disable-role';
import { UserRoles } from '@features/user/user-role.type';
import { useSaveSensitivePersonalInformation } from './use-save-sensitive-personal-information';
import { ControlledToggleButton } from '@components/inputs/ControlledToggleButton';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { CopyToClipboard } from '@components/CopyToClipboard';
import { getDisplayText } from '@features/backend-label/get-display-text';

type Props = {
  orderId: number;
  spi: ISensitivePersonalInformation | null;
  setSpi: React.Dispatch<React.SetStateAction<ISensitivePersonalInformation | null>>;
};

export const SensitivePersonalInformationDrawer: React.FC<Props> = ({ orderId, spi, setSpi }) => {
  const backendLabels = useAppBackendLabels(['disabilityTypeOptions', 'conditionOptions']);
  const { submitHandler, isLoading, control, watch, formState } = useSaveSensitivePersonalInformation(
    orderId,
    spi,
    setSpi,
  );

  const isDisabledForAssessorAndQA = useDisableRole([UserRoles.assessor, UserRoles.qualityAssurance]);

  const disabilityTypeIds = watch('disabilityTypeIds');
  const conditionIds = watch('conditionIds');

  const conditions = getDisplayText(conditionIds, backendLabels.conditionOptions);

  return (
    <>
      <Typography variant="h4" component="h1">
        Sensitive Personal Information
      </Typography>
      <form onSubmit={submitHandler}>
        <Grid container spacing={2} columns={{ xs: 4, md: 8, lg: 12 }} sx={{ mt: 1 }}>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledFileField
              fullWidth
              control={control}
              name="dsa1pdfFile"
              label="Upload DSA 1 PDF"
              disabled={isDisabledForAssessorAndQA}
              fileDetails={spi?.dsa1PdfFilePath}
              buttonLabel="Preview DSA 1 PDF"
              isPreview
              inputProps={{
                accept: '.pdf',
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledAutocomplete
              control={control}
              name="disabilityTypeIds"
              options={backendLabels.disabilityTypeOptions}
              label="Disability Types"
              disabled={isDisabledForAssessorAndQA}
              isLoading={backendLabels.isLoading}
              multiple
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <Stack direction="row" spacing={2}>
              <ControlledAutocomplete
                control={control}
                name="conditionIds"
                label="Disability Conditions"
                options={backendLabels.conditionOptions}
                filterPredicate={(option): boolean => disabilityTypeIds?.includes(option.disabilityTypeId)}
                disabled={isDisabledForAssessorAndQA}
                isLoading={backendLabels.isLoading}
                multiple
                noOptionsText="First select Disability Type"
              />
              <CopyToClipboard
                variant="outlined"
                tooltipText="Copy Conditions"
                size="medium"
                disabled={!conditions}
                label=""
                textToCopy={conditions}
              />
            </Stack>
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledFileField
              fullWidth
              control={control}
              name="medicalEvidenceFiles"
              label="Upload Medical Evidences"
              disabled={isDisabledForAssessorAndQA}
              fileDetails={spi?.medicalEvidenceFilesPaths}
              buttonLabel="Preview Medical Evidences"
              isPreview
              isMultiple
              inputProps={{
                accept: '.pdf',
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledFileField
              fullWidth
              control={control}
              name="preAssessmentFormFile"
              label="Upload Pre Assessment Form"
              disabled={isDisabledForAssessorAndQA}
              fileDetails={spi?.preAssessmentFormFilePath}
              buttonLabel="Preview Form"
              isPreview
              inputProps={{
                accept: '.pdf',
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledDatePicker
              control={control}
              name="dateOfBirth"
              label="Date Of Birth"
              disabled={isDisabledForAssessorAndQA}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledFileField
              fullWidth
              control={control}
              name="narFile"
              label="Previous Needs Assessment Report"
              disabled={isDisabledForAssessorAndQA}
              fileDetails={spi?.narFilePath}
              buttonLabel="Previous Needs Assessment Report"
              isPreview
              inputProps={{
                accept: '.pdf',
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledToggleButton
              control={control}
              fullWidth
              name="evidNacdDisabilityFlag"
              getLabel={(value: boolean): string => (value ? 'Yes' : 'No')}
              disabled={isDisabledForAssessorAndQA}
              helperText="EVID NACD Disability Flag"
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledTextField
              fullWidth
              control={control}
              name="rejectedDisabilityConditions"
              label="Rejected Disability Conditions"
              disabled={isDisabledForAssessorAndQA}
            />
          </Grid>
          <Grid item xs={4} md={4} lg={6}>
            <ControlledTextField
              fullWidth
              control={control}
              name="rejectedDisabilityTypes"
              label="Rejected Disability Types"
              disabled={isDisabledForAssessorAndQA}
            />
          </Grid>
          <Grid item xs={4} md={8} lg={12}>
            <AppLoadingButton
              fullWidth
              type="submit"
              isLoading={isLoading}
              disabled={isLoading || !formState.isDirty}
              sx={{ mt: 2 }}
            >
              Save
            </AppLoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
