import { IBackendLabelOption } from '@features/backend-label/backend-label.type';

export const RepairAndReplaceTypes = {
  RepairNonCatalogue: 'RepairNonCatalogue',
  EquipmentDamaged: 'EquipmentDamaged',
  EquipmentStolen: 'EquipmentStolen',
} as const;

type Keys = keyof typeof RepairAndReplaceTypes;

export type RepairAndReplaceType = (typeof RepairAndReplaceTypes)[Keys];

export const RepairAndReplaceTypeOptions: IBackendLabelOption<RepairAndReplaceType>[] = [
  {
    value: RepairAndReplaceTypes.RepairNonCatalogue,
    label: 'Equipment Damaged (Repair Non Catalogue)',
  },
  {
    value: RepairAndReplaceTypes.EquipmentDamaged,
    label: 'Equipment Damaged (Repair Catalogue)',
  },
  {
    value: RepairAndReplaceTypes.EquipmentStolen,
    label: 'Equipment Stolen (Replace)',
  },
];
