import React, { SyntheticEvent, useState } from 'react';
import { Control } from 'react-hook-form';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { ControlledTextField } from '@components/inputs/ControlledTextField';
import { VisibilityToggle } from '@components/inputs/VisibilityToggle';
import { useAppTitle } from '@hooks/use-app-title';
import { useToggle } from '@hooks/use-toggle';
import { FormModel } from './form-config';
import { IPasswordResetCredentials } from './password-reset-credentials.interface';

type Props = {
  control: Control<FormModel>;
  credentials: IPasswordResetCredentials;
  handleSetPassword: () => void;
};

export const ConfirmPassword: React.FC<Props> = ({ handleSetPassword, control, credentials }) => {
  const [isPasswordVisible, toggleIsPasswordVisible] = useToggle();
  const [isPasswordConfirmVisible, toggleIsPasswordConfirmVisible] = useToggle();
  const [errorMessage, setErrorMessage] = useState('');

  useAppTitle('Confirm Password');

  const onSubmit = (event: SyntheticEvent): void => {
    event.preventDefault();
    if (credentials.password !== credentials.passwordConfirmation) {
      setErrorMessage('Password and Confirm Password should be same');
    } else {
      handleSetPassword();
    }
  };

  const minLength = 8;

  return (
    <form onSubmit={onSubmit}>
      <Stack direction="column" spacing={2} sx={{ marginTop: 3 }}>
        <Typography variant="subtitle2" color="textSecondary">
          Chose a&nbsp;secure password for your account. Minimum {minLength} characters, including one symbol, upper and
          lower case and number.
        </Typography>
        <ControlledTextField
          control={control}
          name="password"
          label="Password"
          inputProps={{ minLength }}
          InputProps={{
            endAdornment: <VisibilityToggle isVisible={isPasswordVisible} toggle={toggleIsPasswordVisible} />,
          }}
          type={isPasswordVisible ? 'text' : 'password'}
          required
        />
        <ControlledTextField
          control={control}
          name="passwordConfirmation"
          label="Confirm password"
          inputProps={{ minLength }}
          InputProps={{
            endAdornment: (
              <VisibilityToggle isVisible={isPasswordConfirmVisible} toggle={toggleIsPasswordConfirmVisible} />
            ),
          }}
          type={isPasswordConfirmVisible ? 'text' : 'password'}
          required
        />

        {errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        <Button variant="contained" size="large" type="submit" fullWidth>
          Submit
        </Button>
      </Stack>
    </form>
  );
};
