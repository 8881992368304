import React from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type VisibilityToggleProps = {
  isVisible: boolean;
  toggle: () => unknown;
};

export const VisibilityToggle: React.FC<VisibilityToggleProps> = ({ isVisible, toggle }) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={toggle}>{isVisible ? <Visibility /> : <VisibilityOff />}</IconButton>
    </InputAdornment>
  );
};
