import { appMutationFactory } from '@api/api-slice';
import { AnonymousEndpoints } from '@api/anonymous-endpoints';
import { INotificationResponse } from '@api/notification-response.interface';
import { IPasswordResetCredentials } from './password-reset-credentials.interface';
import { ToSnakeCase, modelToSnakeCase } from '@utils/model-to-snake-case';

export interface IVerifyEmailRequest {
  account: ToSnakeCase<Pick<IPasswordResetCredentials, 'email'>>;
}

export interface IConfirmRequest {
  account: ToSnakeCase<IPasswordResetCredentials>;
}

export const buildVerifyEmail = appMutationFactory<string, INotificationResponse | void>({
  query: (email) => ({
    url: AnonymousEndpoints.resetPassword,
    method: 'POST',
    data: <IVerifyEmailRequest>{ account: { email } },
  }),
});

export const buildConfirmPassword = appMutationFactory<IPasswordResetCredentials, INotificationResponse | void>({
  query: (credentials) => ({
    url: AnonymousEndpoints.resetPassword,
    method: 'PATCH',
    data: createPayload(credentials),
  }),
});

function createPayload(credentials: IPasswordResetCredentials): IConfirmRequest {
  return { account: modelToSnakeCase(credentials) };
}
